<template>
  <div class="wrap">
    <div class="The_header">
      <ul class="bg-fff">
        <li class="left">
          <router-link to="/profile">
            <i class="iconfont icon-fanhui"></i>
          </router-link>
        </li>
        <li>{{ t("common.title.streamRecords") }}</li>
      </ul>
    </div>
    <div class="Account_fixed">
      <ul>
        <li>
          <p class="p_select">
            <i class="iconfont icon-xia2"></i>
            <select v-model="streamType">
              <option v-for="option in options" :key="option.key" :value="option.value">
                {{ option.label }}
              </option>
            </select>
          </p>
        </li>
        <li v-if="userDetail">
          {{ t("profile.label.total") }}:
          <b>{{ formatMoney(userDetail.amount) }}</b>
        </li>
      </ul>
      <ul class="flexUl">
        <a-date-picker
          v-model:value="startTime"
          :placeholder="t('common.date.SELECT_START_DATE')"
        />
        <a-date-picker
          v-model:value="endTime"
          :placeholder="t('common.date.SELECT_END_DATE')"
        />
        <a-button type="primary" class="button1" @click="search">{{t("common.button.search")}}</a-button>
      </ul>
    </div>
    <BetterScroll
      v-if="alreadyReq"
      ref="scrollRef"
      @pullingUp="fetchMorePage"
      class="BetterScroll"
    >
      <div class="main main_page">
        <div class="Account_list">
          <div :style="{height:'49px'}"></div>
          <div class="no_data" v-if="!listItems || listItems.length === 0">
            <p>{{ t("common.message.noRecords") }}</p>
          </div>
          <div class="bata" v-else>
            <ul :key="index" v-for="(item, index) in listItems">
              <div @click="showRemark(+item.logType,item.remark)">
                <h2 :class="locale==='es'?'iconfont icon-meiyuanzhuanchu':'iconfont icon-zhuanzhang2'"></h2>
                <h3>{{ t(`common.streamType.${item.logType}`) }}</h3>
                <li>
                  <span
                    :class="{
                      'c-red': (item.atfAmount - item.befAmount) > 0,
                      'c-666': (item.atfAmount - item.befAmount) <= 0,
                    }"
                  >
                    {{ formatMoneySign(item.atfAmount - item.befAmount) }}
                  </span>
                </li>
                <li>{{ formatShortDateTime(item.createDate) }}<span v-if="sysDataDictionaryWbObj?.CZBZ?.dataOnOff && (+item.logType === 9)" class="c-red2">{{t('extra.remark')}}</span></li>
              </div>
            </ul>
          </div>
        </div>
      </div>
    </BetterScroll>

    <AModal
      v-model:visible="visible"
      centered
      :footer="null"
      :title="t('extra.remark')"
      @ok="visible = false"
      wrapClassName="Sys_Popup"
      width="85%"
      :zIndex="9999"
    >
      <div class="Sys_Popup_wrap">
        <div class="box text-pre-line">
          {{ remarkObj }}
        </div>
      </div>
    </AModal>
  </div>
</template>
<script>
import { ref, watch, onMounted, reactive } from "vue";
import BetterScroll from "@/components/BetterScroll.vue";
import {
  formatMoney,
  formatMoneySign,
  formatServerDateTime,
  formatShortDateTime,
} from "@/utils/formatter";
import { useI18n } from "@/lang";
import common from "@/lang/en/common.js";
import api from "@/api";
import { userDetail } from "@/hooks/userDetail";
import moment from 'moment-timezone'
import dayjs from 'dayjs'
import { sysDataDictionaryWbObj } from "@/hooks/sysDataDictionaryWbObj";
export default {
  components: { BetterScroll },
  setup() {
    const { t,locale } = useI18n();
    const scrollRef = ref(null);
    const streamType = ref(0);
    const remarkObj = ref('');
    let options = Object.keys(common.streamType).map((k) => ({
      key: k,
      label: t(`common.streamType.${k}`),
      value: k,
    }));

    const visible = ref(false);
    const startTime = ref(dayjs(new Date()));
    const endTime = ref(dayjs(new Date()));
    const isStopReq = ref(false);
    const listItems = reactive([]);
    const currentPage = ref(0);
    const alreadyReq = ref(false);
    const search = ()=>{
      currentPage.value = 0
      isStopReq.value = false
      listItems.splice(0,listItems.length)
      if(!startTime.value || !endTime.value){
        return
      }
      fetchMorePage()
    }
    const fetchMorePage = () => {
      if (isStopReq.value) {
        return;
      }
      let formToSend = {}
      formToSend.current = currentPage.value + 1
      formToSend.size = 10
      formToSend.startTime = moment(new Date(dayjs(startTime.value.$d).format('YYYY/MM/DD')+' 00:00:00')).format('YYYY-MM-DD HH:mm:ss')
      formToSend.endTime = moment(new Date(dayjs(endTime.value.$d).format('YYYY/MM/DD')+' 23:59:59')).format('YYYY-MM-DD HH:mm:ss')
      if(+streamType.value){
        formToSend.type=streamType.value
      }
      api
        .amountLog(formToSend)
        .then((res) => {
          if (res?.data?.success) {
            alreadyReq.value = true;
            if (+res.data.data?.pages <= +currentPage.value+1) {
              isStopReq.value = true;
            }
            currentPage.value += 1;
            listItems.push(...res.data.data?.records);
          }
        })
    };

    onMounted(() => {
      fetchMorePage();
    });

    watch(listItems, () => {
      if (scrollRef.value) {
        scrollRef.value.refresh();
      }
    });

    function showRemark(logType, remark){
      remarkObj.value = ''
      if(!sysDataDictionaryWbObj.value?.CZBZ?.dataOnOff)return
      if(+logType === 9){
        visible.value = true
        remarkObj.value = remark
      }
    }

    return {
      t,
      locale,
      scrollRef,
      options,
      streamType,
      formatMoney,
      formatMoneySign,
      formatServerDateTime,
      formatShortDateTime,
      fetchMorePage,
      userDetail,
      endTime,
      startTime,
      alreadyReq,
      search,
      listItems,
      sysDataDictionaryWbObj,
      showRemark,
      visible,
      remarkObj,
    };
  },
};
</script>
<style lang="scss" scoped>
.wrap .main {
  position: relative;
  top: 0;
}
.flexUl{
  display: flex;
  box-sizing:border-box;
  padding-right: 18px;
}
.button1{
  height: 40px;
}
.Account_list ul li:nth-child(5){

}
.c-red2{
  text-decoration:underline;
  font-size: 12px;
  color: red;
  display: block;
  float: right;
}
</style>
